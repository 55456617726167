<template>
  <div>
    <!-- Start card wrapper -->
    <v-card class="SearchWrapper">
      <v-layout width="100%" row :algin-start="mdUp" :align-center="!mdUp">
        <!-- Start search title -->
        <v-flex xs12 sm2 style="padding: 3px">
          <p class="FieldLabel"></p>
          <!-- End search title -->
          <!-- Start search text field -->
          <v-text-field
            class="custom-placeholder"
            outlined
            dense
            background-color="#FAFAFA"
            clearable
            v-model="sSearch"
            append-icon="mdi-magnify"
            :placeholder="'Búsqueda general...'"
          >
          </v-text-field>
        </v-flex>
        <!-- <v-flex 
          xs12 
          sm2
          style="padding: 3px">
          <p class="FieldLabel"></p>
          <v-select
            class="custom-placeholder"
            outlined
            dense
            background-color="#FAFAFA"
            clearable
            item-value="sProjectId"
            item-text="sName"
            :items="projectsOptions"
            v-model="projectSelected"
            :placeholder="'Proyecto'">
          </v-select>
        </v-flex> -->
        <v-flex xs12 sm2 style="padding: 3px">
          <p class="FieldLabel"></p>
          <!-- End search title -->
          <!-- Start search text field -->
          <v-select
            class="custom-placeholder"
            outlined
            dense
            background-color="#FAFAFA"
            clearable
            :items="statusOptions"
            item-text="sName"
            item-value="sPurchaseOrderStatusId"
            v-model="statusChosen"
            :placeholder="'Estatus'"
          >
          </v-select>
        </v-flex>
        <v-flex xs12 sm2 style="padding: 3px">
          <p class="FieldLabel"></p>
          <!-- End search title -->
          <!-- Start search text field -->
          <v-select
            class="custom-placeholder"
            outlined
            dense
            background-color="#FAFAFA"
            clearable
            :items="debtDaysOptions"
            v-model="daysSelected"
            :placeholder="'Días de Deuda'"
           
          >
          </v-select>
        </v-flex>

        <v-flex xs12 sm3 style="padding: 3px">
          <p class="FieldLabel"></p>

          <!-- <v-select
            v-model="aProvider"
            :items="arrayProviders"
            item-text="sCompanyName"
            item-value="sSupplierId"
            dense
            multiple
            outlined
          ></v-select> -->

          <v-select
            v-model="aProject"
            :items="itemsProjects"
            :key="itemsProjects.sProjectId"
            class="custom-placeholder"
            item-text="sName"
            item-value="sProjectId"
            placeholder="Selecciona los proyectos"
            dense
            outlined
            multiple
             @change="filterProject()"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip style="height: 25px" v-if="index === 0">
                <span>{{ item.sName }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ aProject.length - 1 }} otros)
              </span>
            </template>
          </v-select>
        </v-flex>
        <v-flex align-end xs12 sm1 style="padding: 10px; text-align: right;">
          <!-- <p
            class="TotalDebtTexts"
            style="
              text-align: right;
              margin-bottom: -0px !important;
              font-weight: bold;
            "
          >
            Deuda Total
          </p>
          <p class="TotalDebtTexts" style="text-align: right; font-weight: 600">
            {{ mask(totalDebt) }} MXN
          </p> -->
          <label style="font-weight: bold;">Deuda total:</label>
        </v-flex>
        <v-flex align-end xs12 sm2 style="padding: 3px; text-align: left">
          <v-btn
            color="primary"
            style="
              color: black;
              
              border-radius: 8px;
              margin-right: -25px;
              height: 40px;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 13px;
              cursor: auto;
            "
            @click="dialog = true"
          >
            {{ mask(totalDebt) }} MXN
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card>
    <!-- End card wrapper -->
  </div>
</template>
<script>
export default {
  data: () => ({
    newSupplier: {
      sFullName: "",
      sEmail: "",
      sCompanyName: "",
      sRFC: "",
      sCompanyEconomicActivity: "",
    },
    dialog: false,
    areaChosen: null,
    statusChosen: null,
    // store search input
    sSearch: "",
    projectSelected: null,
    statusSelected: null,
    daysSelected: null,
    statusOptions: [],
    debtDaysOptions: [0, 15, 30, 60],
    projectsOptions: [],
    itemsProjects: [],
    aProject: [],

    bMenuFinal: false,
    bMenuInitial: false,
    dateStart: null,
    dateEnd: null,
    bLoading: false,
  }),
  beforeMount() {
    this.$store.commit("setSearch", "");
  },
  computed: {
    totalDebt() {
      return this.$store.state.totalDebt;
    },
    supplierSelected() {
      return this.$store.state.supplierSelected;
    },
  },
  methods: {
    mask(price) {
      // Create our number formatter.
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      return formatter.format(price); /* $2,500.00 */
    },
    getProjects() {
      db.get(`${uri}/api/v1/projects/?iPageNumber=1`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        params: {
          sSearch: this.supplierSelected.sCompanyName,
        },
      })
        .then((resp) => {
          this.projectsOptions = resp.data.projects;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getStatus() {
      db.get(`${uri}/api/v1/purchase_order_status`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        params: {
          filter: "Suppliers",
        },
      })
        .then((resp) => {
          this.statusOptions = resp.data.orderStatus;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getDebtDays() {
      db.get(`${uri}/api/v1/projects`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.debtDaysOptions = resp.data.days;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    // get the providers from api
    getArrayProjects() {
      // get Array Providers
      db.get(`${uri}/api/v1/projects/debts`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        params: {
          sSupplierId: this.$store.state.sIdSupplier,
        },
      })
        .then((resp) => {
          this.itemsProjects = resp.data.projects;
          
          for (let i = 0; i < this.itemsProjects.length; i++) {
            const element = this.itemsProjects[i];
            // this.aProject.push(element.sSupplierId);
          }
        })
        .catch((err) => {
        });
    },
    filterProject() {
      this.$store.commit("setProject", this.aProject);
    },
    
  },
  beforeMount() {
    this.$store.commit("setSearch", "");
    this.$store.commit("setStatus", null);
    this.$store.commit("setStartDate", null);
    this.$store.commit("setEndDate", null);
    //  this.$store.commit("setDebtDays", null);
  },
  mounted() {
    this.getStatus();
    this.getArrayProjects();
  },
  watch: {
    supplierSelected: function () {
      // this.getProjects()
      this.getStatus();
      // this.getDebtDays()
    },
    statusChosen: function () {
      this.$store.commit("setStatus", this.statusChosen);
    },
    daysSelected: function () {
      this.$store.commit("setDebtDays", this.daysSelected);
    },
    // set state to local search
    sSearch: lodash.debounce(function (val) {
      this.$store.commit("setSearch", this.sSearch);
    }, 800),
    // cmbProviders() {
    //   this.$store.commit("setProvider", this.aProviders);
    // },
    // aSupplier() {
    //   this.$store.commit("setSupplier", this.aSupplier);
    // },
  },
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
  },
};
</script>
<style>
.TotalDebtTexts {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}
.AddProviderTextField ::placeholder {
  font-size: 12px !important;
  text-transform: uppercase;
}
.AddProviderTextField {
  margin-top: -6px;
}

.FieldLabel {
  text-align: left;
  font: normal normal normal 12px/14px "Raleway", sans-serif;
  margin-bottom: 0px !important;
  margin-left: 20px;
  z-index: 100;
  color: #008b83;
  opacity: 1;
}
.custom-placeholder input::placeholder {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000000;
}
.custom-placeholder input::-moz-placeholder {
  text-align: left;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000000;
}
</style>
<style
  scoped>
.v-select__slot,
.v-text-field .v-label {
  font-family: "Poppins", sans-serif;
}

.searchTextField {
  margin-top: -28px;
}
.SearchWrapper {
  max-height: 80px;
  padding-left: 10px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 40px transparent !important;
  border-radius: 30px !important;
  width: 100%;
}
</style>